.findcontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    background: linear-gradient(rgba(0, 0, 0, 0.46), rgba(0, 0, 0, 0.564)), 
                url(../Assets/1.jpg);
    background-size: cover;
    background-position: center;
    
  }
  
  /* Default styles for large screens */
  .heading {
    padding: 20px 0px;
    font-size: 3rem;
    color: #03045e;
  }
  
  .findbox {
    display: flex;
    gap: 100px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .findbtn {
    background-color: #03045e;
    padding: 30px 50px;
    border-radius: 20px;
    color: white;
    font-size: 1.5rem;
    border: 1px solid white;
  }
  
  .findbtn:hover {
    background-color: #0077b6;
    cursor: pointer;
  }
  
  /* Tablets (max-width: 1024px) */
  @media (max-width: 1024px) {
    .heading {
      font-size: 2.5rem;
      padding: 15px 0px;
    }
  
    .findbox {
      gap: 150px;
    }
  
    .findbtn {
      padding: 25px 40px;
      font-size: 1.3rem;
    }
  }
  
  /* Small tablets and large phones (max-width: 768px) */
  @media (max-width: 768px) {
    .heading {
      font-size: 2rem;
      padding: 10px 0px;
    }
  
    .findbox {
      flex-direction: column;
      gap: 50px; /* Reduce gap and change layout to column */
    }
  
    .findbtn {
      padding: 20px 35px;
      font-size: 1.2rem;
    }
  }
  
  /* Small phones (max-width: 480px) */
  @media (max-width: 480px) {
    .findcontainer {
      height: auto; /* Adjust the height for small screens */
      padding: 20px;
    }
  
    .heading {
      font-size: 1.8rem;
      padding: 10px 0;
      text-align: center; /* Center the heading on small screens */
    }
  
    .findbox {
      flex-direction: column; /* Stack the buttons vertically */
      height: 50vh;
    }
  
    .findbtn {
      padding: 15px 25px;
      font-size: 1rem;
      width: 100%; /* Full width for buttons on small screens */
      text-align: center;
    }
  }
  