/* Container for the form */
.sellerform-container {
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  /* Form styles */
  .sellerform-form {
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 600px;
    margin-top: 80px;
  }
  
  /* Label styles */
  .sellerform-label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 16px;
    color: #333;
  }
  
  /* Input field styles */
  .sellerform-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  /* Role checkbox section */
  .sellerform-role {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  
  /* Button styles */
  .sellerform-button {
    width: 100%;
    padding: 15px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* Button hover effect */
  .sellerform-button:hover {
    background-color: #218838;
  }
  