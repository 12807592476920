/* Container for the entire form */
.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    background-color: #f0f4f8;
  }
  
  /* Form box */
  .form-box {
    background-color: #ffffff;
    padding: 30px;
    margin-top: 80px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 450px;
    max-width: 90%;
  }
  
  /* Form title */
  .form-box h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  /* Label for input fields */
  .form-box label {
    font-size: 14px;
    color: black;
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
  }
  
  /* Input fields */
  .form-box input[type="text"],
  .form-box input[type="number"],
  .form-box select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #333;
    transition: border-color 0.3s ease;
  }
  
  /* Input focus style */
  .form-box input:focus,
  .form-box select:focus {
    border-color: #007bff;
    outline: none;
  }
  
  /* Checkbox inputs */
  .form-box .checkbox-group {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  
  .form-box .checkbox-group input[type="checkbox"] {
    margin-right: 10px;
  }
  
  .form-box .checkbox-group label {
    margin-right: 20px;
    color: #555;
  }
  
  /* Investment range */
  .investment-range {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .investment-range input[type="text"] {
    width: 45%;
  }
  
  /* Submit button */
  .form-box button {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .form-box button:hover {
    background-color: #0056b3;
  }
  
  /* Text-centered for extra details */
  .form-box .centered-text {
    text-align: center;
    margin-top: 20px;
    color: #555;
  }



  /* Hide the default file input */
input[type="file"] {
  display: none;
}

/* Style the custom file upload label */
.custom-file-upload {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
}

.custom-file-upload:hover {
  background-color: #0056b3;
}

  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .form-box {
      padding: 20px;
    }
  
    .investment-range input[type="text"] {
      width: 100%;
    }
  }
  