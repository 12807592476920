nav {
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #48cae4;
  color: #03045e;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 98;
}

nav h1 {
  padding: 0 20px;
}

nav ul {
  display: flex;
  gap: 20px;
  padding: 0px 30px;
  cursor: pointer;
}

nav ul li {
  list-style: none;
}

.txtcolor {
  color: #03045e;
  font-weight: 600;
}

.hamburger {
  width: 50px;
  padding: 0px 20px;
  display: none;
}

.sasthalogo {
  width: 100px;
  padding-left: 20px;
}

@media screen and (max-width: 800px) {
  nav h1 {
    padding: 0 100px;
  }

  .hamburger {
    display: block;
    position: absolute;
    z-index: 100;
    width: 25px;
  }
  nav h1 {
    font-size: 1.5rem;
    margin-left: 10px;
  }

  nav ul {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    left: -99%;
    background-color: #48cae4;
    height: 100vh;
    width: 80%;
    padding-top: 100px;
    font-size: 1rem;
    transition: all 0.5s;
  }

  nav ul li {
    padding-bottom: 20px;
  }

  .reset {
    left: 0%;
  }

  .sasthalogo {
    width: 70px;
    padding-left: 70px;
  }
}
